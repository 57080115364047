<template>
  <div class="blacklist-page">
    <vuestic-widget >
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Blacklist</span>
          <div class="d-flex justify-content-end align-items-center">
            <button class="btn btn-sm btn-primary mr-2" @click="onClickImport">
              <span><i class="fa fa-upload"></i> Import</span>
            </button>
            <button type="button" class="btn btn-primary btn-sm" @click="openModal">Add New <i class="fa fa-plus"></i></button>
          </div>
        </div>
      </template>
      <div>
      <div class="row mt-4">
        <div class="col-md-12">
          <div class="pb-2 mb-3">
            <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
              <div class="d-flex align-items-center justify-content-end flex-row mr-3">
                <search-input
                  name="searchInput"
                  v-model="searchFilter"
                  @input="debounceSearch()"
                />
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center" v-if="isLoading">
            <span class="atom-spinner-wrapper">
              <atom-spinner slot="loading" :animation-duration="1500" :size="120" color="rgb(53,120,198)"/>
            </span>
          </div>
          <div class="col-md-12" v-else>
            <datatable v-bind="ipTableOptions" class="le-datatable"/>
          </div>
        </div>
      </div>
      </div>
    </vuestic-widget>
    <vuestic-modal
      :isOpen="isOpenModal"
      @cancel="closeModal"
      :cancelShown="false"
      :okShown="false"
    >
      <span slot="title">Add new blacklist</span>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form @submit.prevent="handleSubmit(handleAddBlacklist)">
          <div class="row">
            <div class="col-md-12">
              <text-input name="ip" v-model="newBlacklist.ip" label="IP"  />
            </div>
            <div class="col-md-12">
              <phone-number-input v-model="newBlacklist.phone" name="phone" :onlyCountries="[]" :disabledFetchingCountry="false" label="Phone"
                :required="false"/>
            </div>
          </div>
          <div class="row mt-5">
            <div class="col-md-12 d-flex justify-content-center">
              <button type="button" class="btn btn-danger mr-2" @click="closeModal">Cancel</button>
              <button class="btn btn-primary" :disabled="isLoadingSave || (!newBlacklist.ip && !newBlacklist.phone)" style="min-width: 80px;">
                <atom-spinner v-if="isLoadingSave" slot="loading" :animation-duration="1500" :size="14" color="#FFF" style="margin: auto;"/>
                <span v-else>Save</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
    <vuestic-modal 
      :isOpen="isOpenModalDelete"
      :hideDefaultActions="true"
      @cancel="closeModalDelete"
      okText="Delete"
      :cancelShown="false"
      @ok="handleRemoveBlacklist"
      okClass="btn btn-danger btn-primary"
    >
      <span slot="title" class="text-danger">Delete Blacklist</span>
      <p v-if="selectedBlacklist && selectedBlacklist.ip">Are you sure you want to delete <strong>"<span >{{ selectedBlacklist && selectedBlacklist.ip }}</span>"</strong>?</p>
      <p v-else>Are you sure you want to delete <strong>"<span >{{ selectedBlacklist && selectedBlacklist.phone | phone }}</span>"</strong>?</p>
    </vuestic-modal>
    <vuestic-modal
      :isOpen="isShowImportModal"
      @ok="handleImport"
      @cancel="isShowImportModal = false"
      okText="Import"
      cancelText="Close"
      :closeOnOk="false"
      :processing="isLoadingImport"
      :okDisabled="!importData.attach"
      okClass="btn btn-primary"
      :force="true"
    >
      <span slot="title" class="font-weight-bold">Import</span>

      <div class="row">
        <div class="col-md-12 mt-2">
          <p class="font-weight-bold">
            <span>You can download sample upload file <a class="text-primary" target="_blank" href="/Template_Blacklist.xlsx">here</a>. <br/>You can upload upto 5000 records at a time</span>
          </p>
        </div>
        <div class="col-md-12">
          <file-upload
            @upload="onUploadFile"
            @delete="onDeleteFile"
            :show-upload-button="false"
            type="Excel"
            types=".csv, .xlsx"
            :file="importData.attach"
          >
            <span slot="title">EXCEL FILE ( MAX 5k RECORDS )</span>
          </file-upload>
        </div>
      </div>
    </vuestic-modal>
  </div>
</template>
<script>
import Action from './Action'
import components from '../../common/tables/comps'
import SearchInput from '../../common/SearchInput'
import debounce from "debounce"
import FileUpload from "../../common/FileUpload";

export default {
  components: {
    SearchInput,
    FileUpload,
  },
  data () {
    return {
      isLoading: false,
      isOpenModal: false,
      isLoadingSave: false,
      isOpenModalDelete: false,
      selectedBlacklist: null,
      ipTableOptions: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          return [
            {
              title: "Ip",
              field: "ip",
              label: "Ip",
              tdClass: "center",
              sortable: true,
              visible: true
            },
            {
              title: "Phone",
              field: "phone",
              label: "Phone",
              tdClass: "center",
              tdComp: "TdPhone",
              sortable: true,
              visible: true
            },
            {
              title: "Network Org",
              field: "network_org",
              label: "Network Org",
              tdClass: "center",
              sortable: false,
            },
            {
              title: "Location",
              field: "address",
              label: "Location",
              tdClass: "center",
              sortable: false,
            },
            { 
              title: 'Is VPN?', 
              field: 'vpn',
              tdComp: 'TdBoolean',
              sortable: true,
            },
            {
              title: '',
              tdComp: Action,
              visible: 'true',
              tdClass: 'center',
            },
          ]
        })(),
        data: [],
        total: 0,
        summary: {},
        query: {
          type: 'ip',
        },
        xprops: {
          eventbus: new Vue()
        }
      },
      newBlacklist: {},
      searchFilter: null,
      isShowImportModal: false,
      isLoadingImport: false,
      importData: {
        attach: null,
      },
    }
  },

  watch: {
    'ipTableOptions.query': {
      handler() {
        this.loadData()
      },
      deep: true
    },
  },

  mounted () {
    this.ipTableOptions.xprops.eventbus.$on('openDeleteModal', row => {
      this.isOpenModalDelete = true
      this.selectedBlacklist = row
    })
  },

  methods: {
    onClickImport() {
      this.importData = {
        attach: null,
      }
      this.isShowImportModal = true;
    },
    handleImport() {
      if (!this.importData.attach) return;

      this.isLoadingImport = true

      let importForm = new FormData()
      importForm.append('attach', this.importData.attach)

      this.$store.dispatch('blacklist/import', importForm)
        .then(() => {
          this.isLoadingImport = false
          this.isShowImportModal = false
          this.loadData()
        })
        .catch(() => {
          this.isLoadingImport = false
        })
    },
    onUploadFile(event) {
      if (event.file) {
        this.importData.attach = event.file
      }
    },
    onDeleteFile(event) {
      this.importData.attach = null
    },

    closeModalDelete () {
      this.isOpenModalDelete = false
    },

    openModal () {
      this.isOpenModal = true
    },
    
    closeModal () {
      this.isOpenModal = false
    },

    loadData () {
      this.isLoading = true
      this.ipTableOptions.query.search = this.searchFilter

      this.$store.dispatch('blacklist/all', this.ipTableOptions.query)
        .then((res) => {
          this.ipTableOptions.data = res.data
          this.ipTableOptions.total = res.meta.total
          this.isLoading = false
        })
        .catch((e) => {
          this.isLoading = false
        })
    },

    debounceSearch: debounce(function() {
      this.loadData()
    }, 500),

    handleAddBlacklist () {
      this.isLoadingSave = true
      this.$store.dispatch('blacklist/create', this.newBlacklist)
        .then(() => {
          this.newBlacklist = {}
          this.closeModal()
          this.loadData()
          this.isLoadingSave = false
        })
        .catch(() => {
          this.isLoadingSave = false
        })
    },

    handleRemoveBlacklist () {
      this.$store.dispatch('blacklist/delete', this.selectedBlacklist.id)
        .then(() => {
          this.closeModalDelete()
          this.loadData()
          this.isLoadingSave = false
        })
        .catch(() => {
          this.isLoadingSave = false
        })
    },
  },
}
</script>
<style lang="scss">
  .blacklist-page {
    .widget-body {
      padding: 0;
    }
    .nav-item {
      min-width: 120px;
      text-align: center;
    }
  }
</style>
